import React, { Component } from "react";


export default class TimeLine extends Component {

    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth
        };
        this.updateDimensions = this.updateDimensions.bind(this);
    }
    updateDimensions() {
        this.setState({ width: window.innerWidth })
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }


    render() {
        if (this.state.width < 500) {
            return (
                <div className="timeLineMobile">

                    <img id="timeLineMobile" src="/images/timeLineMobile.svg" alt="Zeitstrahl für Mobiles"/>
                    
                </div>
            );
        }
        else {
            return (
                <div className="timeLine">
                    <img id="timeLine" src="/images/timeLine.svg" alt="Zeitstrahl"/>
                </div>
            );
        }
    }
}

