import React, { Component } from "react";


export default class BottomSection extends Component {

  constructor(props) {
    super(props);
      this.state = {
        width: window.innerWidth
      };
      this.updateDimensions = this.updateDimensions.bind(this);
    }
    updateDimensions() {
      this.setState({ width: window.innerWidth})
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }


  render() {
    if (this.state.width < 651){
      return (
        <div className="bottomSection">
         
          <div className="contactPerson">
              <div className="header1">Ihr Ansprechpartner</div>
              <img className="portrait" src="/images/Simperl.jpg"  alt="Benjamin Simperl"></img>
              <div className="header2">Mag. Benjamin Simperl</div>
              <div className="header3">Leiter der Versuchsanstalt</div>
          </div>
          <iframe id="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2699.8902304635717!2d9.721106015819165!3d47.414082209250026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479b14b99efe9f09%3A0xd5e151b18153dde!2sH%C3%B6here%20technische%20Bundeslehr-%20und%20Versuchsanstalt%20Dornbirn!5e0!3m2!1sde!2sat!4v1580742497469!5m2!1sde!2sat"  allowFullScreen=""></iframe>
        </div>
      );
    }
    else{
      return (
        <div className="bottomSection">
          <iframe id="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2699.8902304635717!2d9.721106015819165!3d47.414082209250026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479b14b99efe9f09%3A0xd5e151b18153dde!2sH%C3%B6here%20technische%20Bundeslehr-%20und%20Versuchsanstalt%20Dornbirn!5e0!3m2!1sde!2sat!4v1580742497469!5m2!1sde!2sat"  allowFullScreen=""></iframe>
          <div className="contactPerson">
              <div className="header1">Ihr Ansprechpartner</div>
              <img className="portrait" src="/images/Simperl.jpg"  alt="Benjamin Simperl"></img>
              <div className="header2">Mag. Benjamin Simperl</div>
              <div className="header3">Leiter der Versuchsanstalt</div>
          </div>
        </div>
      );
    }
  }
}