import React, { Component } from "react";


export default class Paragraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
    };
    this.extend = this.extend.bind(this);
  }

  extend(id) {

    var x = document.getElementById("buttonId" + id).textContent;
    if (x == "Weniger anzeigen") {
      document.getElementById("buttonId" + id).innerHTML = "Mehr anzeigen";
    }
    else {
      document.getElementById("buttonId" + id).innerHTML = "Weniger anzeigen";
    }
    document.getElementById("idi" + id).classList.toggle("extended");

  }






  componentDidMount() {
    // fetch("https://versuchsanstalt-htl-dornbirn.cc/versuchsanstalt/items/absatz?fields=*.*.*")
    fetch("https://versuchsanstalt-htl-dornbirn.cc/versuchsanstalt/items/untersuchungen?fields=*.*.*")
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          this.setState({
            isLoaded: true,
            items: result.data
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    const { error, isLoaded, items } = this.state;
    var pairs;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
      <div className="paraWrapper">
        <div className="paraGrid" >
          {items.map(item => (
            <div key={item.id} className="paragraph" >
              <img src={item.bild.data.full_url} alt="versuchsanstalt" className="paragraphImage" />
              <h1>{item.ueberschrift}</h1>
              <p id={("idi" + item.id)} className="para" >
              
                <table>
                  {item.unterpunkte.map(unterpunkt => (
                    <tr>
                      <th><li>{unterpunkt.unterpunkte_id.pruefungsart}</li></th>
                      <td >{unterpunkt.unterpunkte_id.norm}</td>
                    </tr>
                  )
                  )}
                </table>
                
              </p>
              <button id={("buttonId" + item.id)} className="paragraphButt" onClick={() => this.extend(item.id)}>Mehr anzeigen</button>
            </div>

          ))}
          
        </div>
        <div className="more"><h2 className="moreHeader">Weitere Prüfungen auf Anfrage!</h2></div>
      </div>
      );
    }

  }
}