import React, { Component } from "react";


export default class Footer extends Component {



  render() {
    return (
      <div className="footer">
        <div className="footerText">Die Versuchsanstalt der HTL Dornbirn</div>
        <a href="https://www.instagram.com/versuchsanstalt_htl_dornbirn/" target="_blank" ><img className="socialIcon" src="/images/instagram.png" alt="Instagram Icon"/></a>
        <div className="footerText">Höchsterstraße 73 | A 6850 Dornbirn | T +43 5572 3883 200 | F +43 5572 3883 219 | versuchsanstalt@htldornbirn.at | <a style={{color:"white"}} href="/images/Impressum.pdf" target="_blank">Impressum</a> | <a style={{color:"white"}} href="/images/Datenschutz.pdf" target="_blank">Datenschutzerklärung</a></div>
      </div>
    );
  }
}/*
<a href="https://www.google.com" target="_blank"><img className="socialIcon" src="/images/linkedin.png" alt="LinkedIn Icon"/></a> <a href="https://www.google.com" target="_blank"><img className="socialIcon" src="/images/facebook.png" alt="Facebook Icon"/></a> 
*/