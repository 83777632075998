import React, { Component } from "react";
import logo from "../logo.png";
import { Link} from "react-router-dom";


export default class Navbar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      background: 'rgba(0, 0, 0, 0)',
    boxshadow:  '0 0px 0px 0px rgba(0, 0, 0, 0)',
    navOnTop: false,
    width:  window.innerWidth,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }
 
  
  updateDimensions() {
    this.setState({ width: window.innerWidth})
}
  
  listenScrollEvent = e => {
  
    
    if (window.scrollY < 10){
      this.setState({background: 'rgba(0, 0, 0, 0)',
      boxshadow: '0 0px 0px 0px rgba(0, 0, 0, 0)'
      })
    }
    else if (window.scrollY > 10) {
      this.setState({background: 'black',
      boxshadow: '0 1px 5px 8px rgba(0, 0, 0, 0.75)'
      })
      
    }
    
  } 
     
    componentDidMount() {
      window.addEventListener("resize", this.updateDimensions);
      // window.addEventListener('scroll', this.listenScrollEvent)
    }
    
  render() {
    if(this.state.width < 481){
      return(
        <div className="navA" id="navbarA">
           
           <div className="nav-itemA" style = {{background: this.state.background, "boxshadow": this.state.boxshadow}}>
             <Link
               className="homeLink"
               to="/"
             >
              &#60;
             </Link>
           </div>
       </div>
      );
    }
    else{
      return (
        <div className="navA" id="navbarA">
           
              <div className="nav-itemA" style = {{background: this.state.background, "boxshadow": this.state.boxshadow}}>
                <Link
                  className="homeLink"
                  to="/"
                >
                 zurück zur Startseite
                </Link>
              </div>
          </div>
      );
    }
   
  }
}