import React, { Component } from "react";


export default class AkkrSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      width: window.innerWidth,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
  
    this.setState({ width: window.innerWidth})
 
  
}


  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    fetch("https://tobias-haemmerle.com/versuchsanstalt/items/akkreditierung?fields=*.*.*")
      .then(res => res.json())
      .then(
        (result) => {
            console.log(result);
          this.setState({
            isLoaded: true,
            items: result.data
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }
  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      if( this.state.width < 481 ){
        return (
          <div className="wrapper1">
          {items.map(item => (
            <div className="blackBorder">
            <div className="redBorder2">
              <div className="akkrSection">
  
                <div><img className="sectionLogo" src="/images/akkreditierung-logo.jpg" alt="AkkreditierungsLogo"/> </div>
                <div className="akkrWrapper">
                    <h1>{item.ueberschrift}</h1>
                    <p>{item.text}</p>
                    <a className="akkrButton" href={item.akkreditierungs_datei.data.full_url} target="_blank">Mehr ...</a>
                </div>
              </div>
          </div>
          </div>
  
             
               
            ))}
            
        </div>
          
          
        );
      }
      else{
        return (
          <div className="wrapper1">
          {items.map(item => (
            <div className="blackBorder">
            <div className="redBorder1">
            <div className="redBorder2">
              <div className="akkrSection">
  
                <div><img className="sectionLogo" src="/images/akkreditierung-logo.jpg"  alt="AkkreditierungsLogo"/> </div>
                <div className="akkrWrapper">
                    <h1>{item.ueberschrift}</h1>
                    <p>{item.text}</p>
                    <a className="akkrButton" href={item.akkreditierungs_datei.data.full_url} target="_blank">Mehr ...</a>
                </div>
              </div>
          </div>
          </div>
          </div>
  
             
               
            ))}
            
        </div>
          
          
        );
      }
      
    }
    
  }
}