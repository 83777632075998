import React, { Component } from "react";


export default class TopSection extends Component {

  render() {
    return (
      <div className="topSectionA">

        <div className="topText">
          <div className="textA">Qualität steht bei uns an erster Stelle!</div>
          <div className="akkrButtonWrap"><a id="akkrButton" href="images/Akkreditierungsbescheid.pdf" target="_blank">Akkreditierungsbescheid</a></div>
        </div>
       
      </div>
    );
  }
}