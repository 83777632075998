  
import React from "react";

export default function Section({subtitle, id}) {
  return (
    <div>
      <div id={id}>
        {subtitle}
      </div>
    </div>
  )};
