import React from 'react';
import './App.css';
import Home from './Home/Home';
import Akkreditierung from './Akkreditierung/Akkreditierung';
import { Route, Switch } from 'react-router-dom';

function App() {


  return (
    <div>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/akkreditierung" component={Akkreditierung} />
      </Switch>
     
    </div>
    
    );
  }

export default App;

/*  <Section
          subtitle={<Team/>}
          id="6"
      />
  
        
         <a href="/images/test.pdf" target="_blank">pdflink</a>
        
        */

