import React, { Component } from "react";


export default class OverlapBox extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    }
    this.updateDimensions = this.updateDimensions.bind(this);
  }
  updateDimensions() {

    this.setState({ width: window.innerWidth })


  }
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  render() {

    return (
      <div className="overlapBox">
        <p>Wir sind ein akkreditiertes Prüflabor nach <span>EN ISO/IEC 17025:2017</span> in den Bereichen:</p>
        <div className="bereiche">
          <ul>
            <li>Textiltechnische Untersuchungen</li>
            <li>Textilchemische Untersuchungen</li>
            <li>Chemisch- Analytische Untersuchungen</li>
            <li>Begutachtungen</li>
          </ul>
        </div>
      </div>
    );

  }
}