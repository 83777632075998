import React, { Component } from "react";
import { Link, animateScroll as scroll } from "react-scroll";


export default class BackTopButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
          display: 'none',
        };
        
      }
    scrollToTop = () => {
        scroll.scrollToTop();
      };
    
      listenScrollEvent = e => {
        
       
        if (window.scrollY < 1000){
          this.setState({
          display: 'none' 
          })
          
        }
        else if (window.scrollY > 1000) {
          this.setState({
            display: 'block' 
          })
          console.log("yes");
        }
        
      } 
         
        componentDidMount() {
          window.addEventListener('scroll', this.listenScrollEvent)
          
        }


  render() {
    return (
      <div className="backTopButton" onClick={this.scrollToTop} style={{display:this.state.display}}>
            &#8963;
      </div>
    );
  }
}