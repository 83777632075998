import React, { Component } from 'react';
import ImageSlide from './imageSlide.js';


  class Carousel extends Component {
	constructor (props){
		super(props);

		this.state={
			currentImageIndex: 0,
			error: null,
        	isLoaded: false,
			items: [],
			images: [],
			width:  window.innerWidth,
		}
		this.nextSlide = this.nextSlide.bind(this);
		//this.previousSlide = this.previousSlide.bind(this);
		this.slideTo = this.slideTo.bind(this);
		
	}

/*
	previousSlide(){
		const lastIndex = this.state.images.length -1;
		const { currentImageIndex } = this.state;
		const shouldResetIndex = currentImageIndex === 0;
		const index = shouldResetIndex ? lastIndex : currentImageIndex -1;

		this.setState({
			currentImageIndex: index,
		});
	}
*/
	slideTo(id) {
		//var test = this.state.items.length;
		var num = this.state.items[0].id;
		document.getElementById("id" + (this.state.currentImageIndex + num )).classList.toggle("dotActive");
		document.getElementById("id" + id).classList.toggle("dotActive");
			

		this.setState({
			currentImageIndex: id - num,
		});
	}

	componentDidMount() {
		if(this.state.width < 1000){
			fetch("https://versuchsanstalt-htl-dornbirn.cc/versuchsanstalt/items/bilder_galerie_mobile?fields=*.*.*")
			.then(res => res.json())
			.then(
			(result) => {	
				this.setState({
				isLoaded: true,
				items: result.data
				});
				document.getElementById("id" + this.state.items[0].id).classList.toggle("dotActive");
				this.intervalID = setInterval(
					() => this.nextSlide(),
					5000
				);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
				this.setState({
				isLoaded: true,
				error
				});
			}
			)
		}
		else{
			fetch("https://versuchsanstalt-htl-dornbirn.cc/versuchsanstalt/items/bilder_galerie?fields=*.*.*")
			.then(res => res.json())
			.then(
			(result) => {
				this.setState({
				isLoaded: true,
				items: result.data
				});
				document.getElementById("id" + this.state.items[0].id).classList.toggle("dotActive");
				this.intervalID = setInterval(
					() => this.nextSlide(),
					5000
				);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
				this.setState({
				isLoaded: true,
				error
				});
			}
			)
		}
		
		
	  }
	  componentWillUnmount() {
		clearInterval(this.intervalID);
	  }
	nextSlide(){
		//var test = this.state.items.length;
		var num = this.state.items[0].id;

		document.getElementById("id"+ (this.state.currentImageIndex + num)).classList.toggle("dotActive");
		const lastIndex = this.state.images.length -1;
		const {currentImageIndex} = this.state;
		const shouldResetIndex = currentImageIndex === lastIndex;
		const index = shouldResetIndex ? 0 : currentImageIndex +1;
		
		this.setState({
			currentImageIndex: index,
		});
		document.getElementById("id"+ (this.state.currentImageIndex + num)).classList.toggle("dotActive");

	}

	

	

  
	render() {
		const { error, isLoaded, items } = this.state;
		
		if (error) {
			return <div>Error: {error.message}</div>;
		  } 
		else if (!isLoaded) {
			return <div>Loading...</div>;
		  }
		else {
			this.state.images = items.map(item =>(item.image.data.full_url))
			return(
				
			<div className="carousel">
			
				
					
					<div className="whiteBorder"></div>
					<ImageSlide url={ this.state.images[this.state.currentImageIndex] }/>
					<div className="dotWrapper">
					{items.map(item => (
					
					
					<div key={item.id} className="dot" id={"id" + item.id} onClick={() => this.slideTo(item.id)} ></div>
					
					))}
					
					
					</div>

			
				
			</div>
			)
	}
  }
  }    


export default Carousel;

/*

<div className="dot" id={"id"+1} onClick={() => this.slideTo(1)} ></div>
					<div className="dot" id={"id" +2} onClick={() => this.slideTo(2)} ></div>

					*/