import React, { Component } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import logo from "../logo.png";
import Akklink from "./akkLink";

export default class BurgerMenu extends Component{
 


  scrollToTop = () => {
    scroll.scrollToTop();
  };

  onClickMenu = () => {
      document.getElementById("menu").classList.toggle("change");
      document.getElementById("navb").classList.toggle("change");
      document.getElementById("menu-bg").classList.toggle("change-bg");
    }
 
    
  render() {
    return (
      <div>
      
         <img src={logo} alt="Logo" onClick={this.scrollToTop} id="navLogob"/>
              
        <div id="menu-bar">
            <div id="menu" onClick={this.onClickMenu}>
                <div id="bar1" className="bar"></div>
                <div id="bar2" className="bar"></div>
                <div id="bar3" className="bar"></div>
            </div>
            <ul className="navb" id="navb">
              
              <li>
                <Link
                  activeClass="active"
                  to="1"
                  spy={true}
                  smooth={true}
                  offset={-280}
                  duration={750}
                  data-text="Bereiche"
                  onClick={this.onClickMenu}
                >
                  Über Uns
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  to="4"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={750}
                  data-text="Unsere Kunden"
                  onClick={this.onClickMenu}
                >
                  Referenzen
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  to="5"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={750}
                  data-text="Projekte"
                  onClick={this.onClickMenu}
                >
                  Leistungen
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  to="6"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={750}
                  data-text="Kontakt"
                  onClick={this.onClickMenu}
                >
                  Kontakt
                </Link>
              </li>

              <Akklink/>
            </ul>
            
        </div>
        <div className="menu-bg" id="menu-bg"></div>
    </div>
    );
  }
}