import React, { Component } from "react";
import Navbar from "./navbar";
import Section from "./section";
import TopSection from "./topSection";
import OverlapBox from "./overlapBox";
import Carousel from "./carousel";
import Customer from "./customer";
import Paragraph from "./paragraph";
import ContactForm from './contactForm';
import BottomSection from './bottomSection';
import Footer from './footer';
import "./Home.css";
import BurgerMenu from "./burgerMenu";
import TimeLine from "./timeLine";

export default class Home extends Component {

  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    }
    this.updateDimensions = this.updateDimensions.bind(this);
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth })
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  render() {

    if (this.state.width < 1000) {

      return (
        <div className="wrapper">
          <BurgerMenu />

          <TopSection />

          <Section
            subtitle={<OverlapBox />}
            id="1"
          />


          <Section
            subtitle={<TimeLine />}
            id="2"
          />

          <Section
            subtitle={<Carousel />}
            id="3"
          />


          <Section
            subtitle={<Customer />}
            id="4"
          />

          <Section
            subtitle={<Paragraph />}
            id="5"
          />

          <Section
            subtitle={<ContactForm />}
            id="6"
          />

          <BottomSection />

          <Footer />

        </div>

      )

    }
    else {

      return (
        <div className="wrapper">

          <Navbar />

          <TopSection />

          <Section
            subtitle={<OverlapBox />}
            id="1"
          />


          <Section
            subtitle={<TimeLine />}
            id="2"
          />

          <Section
            subtitle={<Carousel />}
            id="3"
          />



          <Section
            subtitle={<Customer />}
            id="4"
          />

          <Section
            subtitle={<Paragraph />}
            id="5"
          />

          <Section
            subtitle={<ContactForm />}
            id="6"
          />

          <BottomSection />

          <Footer />

        </div>

      );
    }


  }
}


/*  <Section
          subtitle={<Team/>}
          id="6"
      />


         <a href="/images/test.pdf" target="_blank">pdflink</a>

        */

