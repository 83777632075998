import React, { Component } from "react";
import {Link} from "react-router-dom";

export default class NavbarTest extends Component {


  render() {
    return (
            <li className="nav-item">
              <Link
                to="/akkreditierung"
                className="akkLink"
              >
                Akkreditierung
              </Link>
            </li>
    );
  }
}