import React from 'react';

//for help go to: https://blog.mailtrap.io/react-send-email/#Sending_emails_with_EmailJS


export default class ContactForm extends React.Component {
  constructor(props) {
	super(props);
    this.state = {
      feedback: '',
      name: '',
      firstname: '',
      lastname: '',
      company: '',
      email: 'versuchsanstalt@htldornbirn.at',
      firstNErr: false,
      lastNErr: false,
      mailErr: false,
      textErr: false,
      width: window.innerWidth
    };
    this.handleChangeFirstName = this.handleChangeFirstName.bind(this);
    this.handleChangeLastName = this.handleChangeLastName.bind(this);
    this.handleChangeCompany = this.handleChangeCompany.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChange = this.handleChange.bind(this); 
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth})
}
  

  sendFeedback (templateId, variables) {
	window.emailjs.send(
  	'outlook', templateId,
  	variables
  	).then(res => {
    	alert('E-Mail erfolgreich gesendet.✔ Danke für Ihre E-Mail!');
  	})
  	// Handle errors here however you like, or use a React error boundary
      .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err ,alert("Es ist ein Problem aufgetreten. Bitte versuchen Sie es erneut oder senden Sie uns eine E-Mail an versuchsanstalt@htldornbirn.at.")))
      
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  render() {
    if (this.state.width < 651){
      return (
        <div className="contactForm">
          
         
          <div className="mailForm">
    
          <form className="box">
            <h1 style={{margin:"0",padding:"0",paddingTop:"2vh",fontSize:"7vw",color:"white"}}>Kontaktformular</h1>
            <div style={{color:"grey",textAlign:"left",marginTop:"15px", marginBottom:"-20px",fontSize:"15px"}}>*...Pflichtfelder</div>
            <div className="firstname">
            <input
              type="text"
              id="firstname"
              className="inputBox"
              name="firstname"
              onChange={this.handleChangeFirstName}
              placeholder="Vorname*"
              value={this.state.firstname}
              
              type="text"
            />
            </div>
            <div className="lastname">
            <input
              type="text"
              id="lastname"
              className="inputBox"
              name="lastname"
              onChange={this.handleChangeLastName}
              placeholder="Nachname*"
              value={this.state.lastname}
              error={this.state.mailErr}
            />
            </div>
            <div className="company">
            <input
              type="text"
              id="company"
              className="inputBox"
              name="company"
              onChange={this.handleChangeCompany}
              placeholder="Firma"
              value={this.state.company}
              error={this.state.mailErr}
            />
            </div>
            <div className="emailName">
            <input
              type="text"
              id="emailName"
              className="inputBox"
              name="emailName"
              onChange={this.handleChangeName}
              placeholder="E-Mail Adresse*"
              value={this.state.name}
              error={this.state.mailErr}
              type="email"
            />
            </div>
    
            <div className="mailText">
            <textarea
              
              id="mailText" 
              multiline="true"
              className="inputBox"
              name="mailText"
              onChange={this.handleChange}
              placeholder="Nachricht...*"
              value={this.state.feedback}
              
             
            />
          
            </div>
            <div className="checkBox">
            <input type="checkbox" id="dataCheck" style={{ marginTop:"25px" , cursor: "pointer"}}/>
            <label for="dataCheck" style={{color:"white"}} >Sie erklären sich hiermit einverstanden, dass Sie die <a href="/images/Datenschutz.pdf" target="_blank">Datenschutzerklärung</a> zur Kenntnis genommen haben und diese akzeptieren.</label>
            </div>
            <input type="button" value="Absenden" id="emailButton" onClick={this.handleSubmit} />
    
          </form>
            
          </div>

          <img src="/images/mobileSwish.svg" id="contactImg" alt="Pfeile"/>
        </div>
      )


    }
    else{
      return (
        <div className="contactForm">
          
          <img src="/images/swish.svg" id="contactImg"  alt="Pfeile"/>
          <div className="mailForm">
    
          <form className="box">
            <h1 style={{margin:"0",padding:"0",paddingTop:"2vh",fontSize:"3.5vw",color:"white"}}>Kontaktformular</h1>
            <div style={{color:"grey",textAlign:"left",marginTop:"10px",fontSize:"15px"}}>*...Pflichtfelder</div>
            <div className="firstname">
            <input
              type="text"
              id="firstname"
              className="inputBox"
              name="firstname"
              onChange={this.handleChangeFirstName}
              placeholder="Vorname*"
              value={this.state.firstname}
              
              type="text"
            />
            </div>
            <div className="lastname">
            <input
              type="text"
              id="lastname"
              className="inputBox"
              name="lastname"
              onChange={this.handleChangeLastName}
              placeholder="Nachname*"
              value={this.state.lastname}
              error={this.state.mailErr}
            />
            </div>
            <div className="company">
            <input
              type="text"
              id="company"
              className="inputBox"
              name="company"
              onChange={this.handleChangeCompany}
              placeholder="Firma"
              value={this.state.company}
              error={this.state.mailErr}
            />
            </div>
            <div className="emailName">
            <input
              type="text"
              id="emailName"
              className="inputBox"
              name="emailName"
              onChange={this.handleChangeName}
              placeholder="E-Mail Adresse*"
              value={this.state.name}
              error={this.state.mailErr}
              type="email"
            />
            </div>
    
            <div className="mailText">
            <textarea
              
              id="mailText" 
              multiline="true"
              className="inputBox"
              name="mailText"
              onChange={this.handleChange}
              placeholder="Nachricht...*"
              value={this.state.feedback}
              
             
            />
          
            </div>
            <div className="checkBox">
            <input type="checkbox" id="dataCheck" style={{ marginTop:"25px", textAlign: "left", cursor: "pointer"}}/>
            <label for="dataCheck" style={{color:"white"}} >Sie erklären sich hiermit einverstanden, dass Sie die <a href="/images/Datenschutz.pdf" target="_blank">Datenschutzerklärung</a> zur Kenntnis genommen haben und diese akzeptieren.</label>
            </div>
            <input type="button" value="Absenden" id="emailButton" onClick={this.handleSubmit} /> 
        	 
          </form>
            
          </div>
        </div>
      )
    }
	  
  }

  handleChangeFirstName(event) {
  this.setState({firstname: event.target.value})
  
  }
  handleChangeLastName(event) {
	this.setState({lastname: event.target.value})
  }
  handleChangeCompany(event) {
    this.setState({company: event.target.value})
  }
  handleChangeName(event) {
    this.setState({name: event.target.value})
  }

  handleChange(event) {
    this.setState({feedback: event.target.value})
  }

  handleSubmit (event) {
    window.emailjs.init('user_iid48ql40wuLDtj8KXLV4');
  const templateId = 'template_YfnEwsp1';
  
  

	if((this.state.feedback == ""  || this.state.feedback == null) || (this.state.name == "" || this.state.name == null)  || (this.state.firstname == null || this.state.firstname == "")  || (this.state.lastname == "" || this.state.lastname == null) || (!document.getElementById('dataCheck').checked)){
    
    if((this.state.firstname == null || this.state.firstname == "") && this.state.firstNErr == false){
      document.getElementById("firstname").classList.toggle("boxError");
      this.setState({firstNErr: true})
    }
    else if(!(this.state.firstname == null || this.state.firstname == "") && this.state.firstNErr == true){
      document.getElementById("firstname").classList.toggle("boxError");
      this.setState({firstNErr: false})
    }
    if((this.state.lastname == null || this.state.lastname == "") && this.state.lastNErr == false){
      document.getElementById("lastname").classList.toggle("boxError");
      this.setState({lastNErr: true})
    }
    else if(!(this.state.lastname == null || this.state.lastname == "") && this.state.lastNErr == true){
      document.getElementById("lastname").classList.toggle("boxError");
      this.setState({lastNErr: false})
    }
    if((this.state.name == null || this.state.name == "") && this.state.mailErr == false){  
      document.getElementById("emailName").classList.toggle("boxError");
      this.setState({mailErr: true})
    }
    else if(!(this.state.name == null || this.state.name == "") && this.state.mailErr == true){
      document.getElementById("emailName").classList.toggle("boxError");
      this.setState({mailErr: false})
    }
    if((this.state.feedback == null || this.state.feedback == "") && this.state.textErr == false){
      document.getElementById("mailText").classList.toggle("boxError");
      this.setState({textErr: true})
    }
    else if(!(this.state.feedback == null || this.state.feedback == "") && this.state.textErr == true){
      document.getElementById("mailText").classList.toggle("boxError");
      this.setState({textErr: false})
    }

    if(!document.getElementById('dataCheck').checked){
     alert("Um eine Email zu senden, müssen Sie der Datenschutzerklärung zustimmen.")
    }
		
	}
	else{
		this.sendFeedback(templateId, {message_html: this.state.feedback, from_name: "Nachname: " + this.state.lastname + " Vorname: " + this.state.firstname + " Firma: " + this.state.company, reply_to: this.state.name})
    if(this.state.firstNErr == true){
      document.getElementById("firstname").classList.toggle("boxError");
    }

    if(this.state.lastNErr == true){
      document.getElementById("lastname").classList.toggle("boxError");
    }

    if(this.state.mailErr == true){  
      document.getElementById("emailName").classList.toggle("boxError");
    }

    if(this.state.textErr == true){
      document.getElementById("mailText").classList.toggle("boxError");
    }
    document.getElementById("contactImg").classList.toggle("contactImgActive");

    this.setState({firstname: "", lastname: "", company: "", name: "", feedback: "",firstNErr: false, lastNErr: false, mailErr: false, textErr: false});
    document.getElementById("dataCheck").checked = false;
    setTimeout(function(){
      document.getElementById("contactImg").classList.toggle("contactImgActive");
    }, 3000);
	}
    
    
  }
}