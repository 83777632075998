import React, { Component } from "react";


export default class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      width: window.innerWidth,
      buttonchanged: false,
    };
    this.extend = this.extend.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.maxHeightBeforeExtended = this.maxHeightBeforeExtended.bind(this);
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth })
  }


  extend() {

    
    if (this.state.buttonchanged == false) {
      document.getElementById("customerButton").innerHTML = "Weniger";
      document.getElementById("toExtand").style.maxHeight = "none";
      this.setState({
				buttonchanged: true,
				});
    }
    else {
      document.getElementById("customerButton").innerHTML = "Mehr ...";
      this.maxHeightBeforeExtended();
      this.setState({
				buttonchanged: false,
				});
    }
  }

  maxHeightBeforeExtended() {
    var Element1H =this.state.items[0].logo.height;
    var Element1W =this.state.items[0].logo.width;
    
    var Element2H =this.state.items[1].logo.height;
    var Element2W =this.state.items[1].logo.width;
    var Element3H =this.state.items[2].logo.height;
    var Element3W =this.state.items[2].logo.width;
    var Element4H =this.state.items[3].logo.height;
    var Element4W =this.state.items[3].logo.width;

    if (this.state.width >= 1769) {
      var tempRatio1 = Element1H/Element1W;
      var temp1 = Element1W - 200;
      var tempH1 = Element1H - (tempRatio1*temp1);
      var tempRatio2 = Element2H/Element2W;
      var temp2 = Element2W - 200;
      var tempH2 = Element2H - (tempRatio2*temp2);
      var tempRatio3 = Element3H/Element3W;
      var temp3 = Element3W - 200;
      var tempH3 = Element3H - (tempRatio3*temp3);
      var tempRatio4 = Element4H/Element4W;
      var temp4 = Element4W - 200;
      var tempH4 = Element4H - (tempRatio4*temp4);

      if(tempH4 >= tempH1 && tempH4 >= tempH2 && tempH4 >= tempH3){
        document.getElementById("toExtand").style.maxHeight = tempH4 + 100 + "px";
      }
      else if(tempH3 >= tempH1 && tempH3 >= tempH2){
        document.getElementById("toExtand").style.maxHeight = tempH3 + 100 + "px";
      }
      else if(tempH2 >= tempH1){
        document.getElementById("toExtand").style.maxHeight = tempH2 + 100 + "px";
      }
      else{
        document.getElementById("toExtand").style.maxHeight = tempH1 + 100 + "px";
      }

     
    }
    else if (this.state.width >= 1332) {
      var tempRatio1 = Element1H/Element1W;
      var temp1 = Element1W - 150;
      var tempH1 = Element1H - (tempRatio1*temp1);
      var tempRatio2 = Element2H/Element2W;
      var temp2 = Element2W - 150;
      var tempH2 = Element2H - (tempRatio2*temp2);
      var tempRatio3 = Element3H/Element3W;
      var temp3 = Element3W - 150;
      var tempH3 = Element3H - (tempRatio3*temp3);

      if(tempH4 >= tempH1 && tempH4 >= tempH2 && tempH4 >= tempH3){
        document.getElementById("toExtand").style.maxHeight = tempH4 + 100 + "px";
      }
      else if(tempH3 >= tempH1 && tempH3 >= tempH2){
        document.getElementById("toExtand").style.maxHeight = tempH3 + 100 + "px";
      }
      else if(tempH2 >= tempH1){
        document.getElementById("toExtand").style.maxHeight = tempH2 + 100 + "px";
      }
      else{
        document.getElementById("toExtand").style.maxHeight = tempH1 + 100 + "px";
      }

     
    }
    else if (this.state.width <= 1332) {
      var tempRatio1 = Element1H/Element1W;
      var temp1 = Element1W - 100;
      var tempH1 = Element1H - (tempRatio1*temp1);
      var tempRatio2 = Element2H/Element2W;
      var temp2 = Element2W - 100;
      var tempH2 = Element2H - (tempRatio2*temp2);


      if(tempH2 >= tempH1){
        document.getElementById("toExtand").style.maxHeight = tempH2 + 100 + "px";
      }
      else{
        document.getElementById("toExtand").style.maxHeight = tempH1 + 100 + "px";
      }

     
    }

    else if (this.state.width <= 480) {
      var tempRatio1 = Element1H/Element1W;
      var temp1 = Element1W - 80;
      var tempH1 = Element1H - (tempRatio1*temp1);
      var tempRatio2 = Element2H/Element2W;
      var temp2 = Element2W - 80;
      var tempH2 = Element2H - (tempRatio2*temp2);


      if(tempH2 >= tempH1){
        document.getElementById("toExtand").style.maxHeight = tempH2 + 100 + "px";
      }
      else{
        document.getElementById("toExtand").style.maxHeight = tempH1 + 100 + "px";
      }

     
    }
    
   /*
    else if (Element3H >= Element1H && Element3H >= Element2H && Element3H >= Element4H && this.state.width >= 1332) {
      document.getElementById("toExtand").style.maxHeight = thirdElement + 70 + "px";
    }
    else if (Element2H >= Element1H) {
     
      document.getElementById("toExtand").style.maxHeight = secondElement + 70 + "px";
    }
    else {
      
      document.getElementById("toExtand").style.maxHeight = firstElement + 70 + "px";
    }
   
    
    var firstElement = document.getElementById(this.state.items[0].id).offsetHeight;
    var secondElement = document.getElementById(this.state.items[1].id).offsetHeight;
    var thirdElement = document.getElementById(this.state.items[2].id).offsetHeight;
    var fourthElement = document.getElementById(this.state.items[3].id).offsetHeight;

    if (this.state.width >= 1769 ) {
      if(fourthElement >= firstElement && fourthElement >= secondElement && fourthElement >= thirdElement){
        document.getElementById("toExtand").style.maxHeight = fourthElement + 70 + "px";
      }
      else if(thirdElement >= firstElement && thirdElement >= secondElement && thirdElement >= fourthElement){
        document.getElementById("toExtand").style.maxHeight = thirdElement + 70 + "px";
      }
      else if(secondElement >= firstElement){
        document.getElementById("toExtand").style.maxHeight = secondElement + 70 + "px";
      }
      else{
        document.getElementById("toExtand").style.maxHeight = firstElement + 70 + "px";
      }
      
    }

    /*
    else if (thirdElement >= firstElement && thirdElement >= secondElement && thirdElement >= fourthElement && this.state.width >= 1332) {
      document.getElementById("toExtand").style.maxHeight = thirdElement + 70 + "px";
    }
    else if (secondElement >= firstElement) {
     
      document.getElementById("toExtand").style.maxHeight = secondElement + 70 + "px";
    }
    else {
      
      document.getElementById("toExtand").style.maxHeight = firstElement + 70 + "px";
    }
    */
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    
    fetch("https://versuchsanstalt-htl-dornbirn.cc/versuchsanstalt/items/kunden?fields=*.*.*")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            items: result.data,
            isLoaded: true,
            
          });
       
          if(this.state.isLoaded ==true){
            this.maxHeightBeforeExtended();
            if(this.state.width >600){
              window.addEventListener("resize", this.maxHeightBeforeExtended);
            }
          }
          
        },

        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
      

  }

  
  
  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div className="customerWrapper">
          <div id="toExtand" className="customer">
            {items.map(item => (

              <img key={item.id} id={item.id} src={item.logo.data.full_url} alt="Kunden" className="customerLogo" />
            ))}

          </div>
          <button id="customerButton" className="customerButt" onClick={this.extend} >Mehr ...</button>
        </div>
      );

      
    }
    
   
  }
}