import React from 'react';
import Navbar from "./navbarA";
import TopSection from "./topSectionA";
import "./Akkreditierung.css"
import AkkrParagraph from './akkrParagraph';
import AkkrSection from './akkrSection';
import BackTopButton from './backTopButton';


function Akkreditierung() {


  return (
    <div >
     
      
      <Navbar/>

      <TopSection/>
      
     
      
      
    </div>
    
    );
  }

export default Akkreditierung;

/* 
 <BackTopButton/>

  <AkkrParagraph/>

      <AkkrSection/>
        
         <a href="/images/test.pdf" target="_blank">pdflink</a>
        
        */

