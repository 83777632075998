import React from "react";

const ImageSlide = ({url}) => {
	const styles = {
		backgroundImage: `url(${url})`,
		

	};

	return (
		//<img src={url} alt={url}/>
		<div className="image-slide" style={styles}>
			
		</div>
	)

  }


export default ImageSlide;