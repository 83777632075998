import React, { Component } from "react";


export default class TopSection extends Component {



  render() {
    return (
      <div className="topSection">
        <img id="shapes" alt="shapes" src="/images/shapes.svg" alt="Hintergrundformen"/>
        <div className="text">
          <h1>Die Versuchsanstalt</h1>
          <p>Akkreditiertes Prüfinstitut</p>
          </div>
      </div>
    );
  }
}