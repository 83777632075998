import React, { Component } from "react";
import logo from "../logo.png";
import { Link, animateScroll as scroll } from "react-scroll";
import Akklink from "./akkLink";


export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      background: 'rgba(0, 0, 0, 0)',
    boxshadow:  '0 0px 0px 0px rgba(0, 0, 0, 0)',
    navOnTop: false,
    };
    
  }
  
  scrollToTop = () => {
    scroll.scrollToTop();
  };

  listenScrollEvent = e => {
    
  
    if (window.scrollY < 10){
      this.setState({background: 'rgba(0, 0, 0, 0)',
      boxshadow: '0 0px 0px 0px rgba(0, 0, 0, 0)'
      })
      
    }
    else if (window.scrollY > 10) {
      this.setState({background: '#140F2D',
      boxshadow: '0 1px 5px 8px rgba(0, 0, 0, 0.75)'
      })
    }
    
  } 
     
    componentDidMount() {
      window.addEventListener('scroll', this.listenScrollEvent)
      
    }

  render() {
    return (
      <nav className="nav" id="navbar">
        <div className="nav-content" style = {{background: this.state.background, "boxshadow": this.state.boxshadow}}>
          <img
            src={logo}
            className="nav-logo"
            alt="Logo"
            onClick={this.scrollToTop}
          />
          <ul className="nav-items">
            <li className="nav-item">
              <Link
                activeClass="active"
                to="1"
                spy={true}
                smooth={true}
                offset={-550}
                duration={700}
              >
                Über Uns
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="4"
                spy={true}
                smooth={true}
                offset={-150}
                duration={500}
              >
                Referenzen
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="5"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                Leistungen
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="6"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Kontakt
              </Link>
            </li>

            <Akklink/>

          </ul>
        </div>
      </nav>
    );
  }
}